<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";
  import { onMount, getContext } from "svelte";
  import { scrollContainerContext } from "./contexts.js";

	let className = "";
  export {className as class};
  export let style = "";

  let innerHeight;
  let container;
  const progress = tweened(-1, { duration: 500, easing: cubicOut });

  const onScroll = () => {
    const rect = container.getBoundingClientRect();
    const hiddenZone = 1 / 6;
    if (rect.bottom < innerHeight * hiddenZone) {
      progress.set(-1);
    } else if (rect.top > innerHeight - innerHeight * hiddenZone) {
      progress.set(1);
    } else {
      progress.set(0);
    }
  };

  onMount(() => {
    const interval = setInterval(onScroll, 100);
    setTimeout(() => clearInterval(interval), 2000);
  });
</script>

<svelte:window bind:innerHeight on:scroll={onScroll} />

<div
  bind:this={container}
  class={className}
  style="transform: translateX({50 * $progress}vw); opacity: {1 - Math.abs($progress)};
  {style}">
  <slot />
</div>
