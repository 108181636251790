<script>
  import AutoHide from "./AutoHide.svelte";
  import { spring } from "svelte/motion";

  let mouseOn = false;

  let animationState = spring(0);

  $: {
    if (mouseOn) {
      animationState.set(1);
    } else {
      animationState.set(0);
    }
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .space-before {
    flex: 1 0 10vh;
  }

  .space-after {
    flex: 2 0 10vh;
  }

  a {
    color: var(--foreground-color);
    font-weight: 400;
    font-size: 3em;
  }

  .link-container {
    border: 0px solid var(--foreground-color);
    border-radius: 1em;
    display: inline-block;
    padding: 1em;
  }
</style>

<div class="container">
  <div class="space-before" />
  <AutoHide>
    <h4>Let's create something awesome together.</h4>
  </AutoHide>
  <AutoHide>
    <div
      class="link-container"
      on:mouseover={() => {
        mouseOn = true;
      }}
      on:mouseout={() => {
        mouseOn = false;
      }}
      style="transform: scale({1 + $animationState / 2}) translateY({-10 * $animationState}px);">
      <a href="mailto:jan@kaifer.cz">
        {!mouseOn ? 'Get in touch' : 'jan@kaifer.cz'}
      </a>
    </div>
  </AutoHide>
  <div class="space-after" />
</div>
