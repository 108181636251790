<script>
  import { onMount } from "svelte";
  import { colorStore } from "./stores.js";

  export let color = "#00FF00";

  let container;
  let innerHeight;
  const id = {};

  const onScroll = () => {
    const rect = container.getBoundingClientRect();
    if (
      rect.top < innerHeight / 2 &&
      innerHeight / 2 < rect.bottom &&
      $colorStore !== color
    ) {
      colorStore.set(color);
    }
  };

  onMount(() => {
    const interval = setInterval(onScroll, 100);
    setTimeout(() => clearInterval(interval), 2000);
  });
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 0 0.5em;
  }
</style>

<svelte:window bind:innerHeight on:scroll={onScroll} />

<div bind:this={container} class="container">
  <div class="content">
    <slot />
  </div>
</div>
