<script>
  import AutoHide from "./AutoHide.svelte";
  import {spring} from 'svelte/motion'
  import { onMount } from 'svelte';

  export let name, src, href;

  const coords = spring({ x: 0, y: 0 }, {
		stiffness: 0.2,
		damping: 0.4
	});

  let lastX = 0, lastY = 0;
  let height = 0, width = 0;
  let mouseOn = false;

	function handlePanStart(event) {
		coords.stiffness = coords.damping = 1;
    mouseOn = true;
    console.log("start")

    lastX = event.offsetX - width/2;
    lastY = event.offsetY - height/2;
	}

	function handlePanMove(event) {
    lastX += event.movementX;
    lastY += event.movementY;

		coords.set({x: lastX, y: lastY});
	}

	function handlePanEnd(event) {
		coords.stiffness = 0.03;
		coords.damping = 0.1;
    mouseOn = false;

		coords.set({ x: 0, y: 0 });
	}
</script>

<style lang="scss">
  * {
  }

  :global(.project-autohide) {
    z-index: 0;

    &:hover {
      z-index: 100;
    }
  }

  .container {
    margin: 3em 2em;
    width: calc(100% - 2em);
    max-width: 1000px;
    display: flex;
    transition: transform ease 200ms;

    &:hover {
      transform: scale(1.1);
    }

    & > a {
      position: relative;
      width: 100%;
      color: white;
      overflow: hidden;
      box-shadow: black 15px 10px 20px -10px;

      img {
        width: 100%;
        margin-bottom: -40.5px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
        background-color: #0008;
      }

      .about {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name {
          font-size: 2em;
        }
      }

      &:hover {
        box-shadow: #000a 30px 20px 30px 0px;

        .overlay {
          background-color: #0000;
        }

        .about {
          opacity: 0;
        }
      }
    }
  }
</style>

<AutoHide
  class="project-autohide"
  style="width: 100%; display: flex; flex-direction: column; align-items:
  center; ">
  <div class="container">
    <a 
    style="transform: translate({$coords.x*0.06}px, {$coords.y*0.06}px)"
    bind:clientWidth={width} bind:clientHeight={height} {href} on:mouseenter={handlePanStart} on:mouseleave={handlePanEnd} on:mousemove={handlePanMove}
    >
      <img {src} alt="project-image" />
      <div class="overlay" />
      <div class="about">
        <span class="name">{name}</span>
      </div>
    </a>
  </div>
</AutoHide>
