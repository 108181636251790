class Color {
  constructor(r, g, b) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  get cmyk() {
    let cyan = 255 - this.r;
    let magenta = 255 - this.g;
    let yellow = 255 - this.b;
    let black = Math.min(cyan, magenta, yellow);
    cyan = (cyan - black) / (255 - black);
    magenta = (magenta - black) / (255 - black);
    yellow = (yellow - black) / (255 - black);

    return {
      c: cyan,
      m: magenta,
      y: yellow,
      k: black / 255,
    };
  }

  get hex() {
    return (
      "#" +
      this.r.toString(16).padStart(2, "0") +
      this.g.toString(16).padStart(2, "0") +
      this.b.toString(16).padStart(2, "0")
    );
  }
}

const from_hex = (str) => {
  return new Color(
    parseInt(str.slice(1, 3), 16),
    parseInt(str.slice(3, 5), 16),
    parseInt(str.slice(5, 7), 16)
  );
};

const from_cmyk = (color) => {
  let R = color.c * (1.0 - color.k) + color.k;
  let G = color.m * (1.0 - color.k) + color.k;
  let B = color.y * (1.0 - color.k) + color.k;
  R = Math.round((1.0 - R) * 255.0);
  G = Math.round((1.0 - G) * 255.0);
  B = Math.round((1.0 - B) * 255.0);
  return new Color(R, G, B);
};

export const mixColors = (a, b, t) => {
  const color = { c: 0, m: 0, y: 0, k: 0 };
  [
    [a, 1 - t],
    [b, t],
  ].forEach(([cHex, w]) => {
    const c = from_hex(cHex).cmyk;
    color.c += c.c * w;
    color.m += c.m * w;
    color.y += c.y * w;
    color.k += c.k * w;
  });
  return from_cmyk(color).hex;
};
