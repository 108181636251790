<script>
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { quadInOut as easing } from "svelte/easing";

  import AutoHide from "./AutoHide.svelte";
  import View from "./View.svelte";

  let index = 0;
  const iam = [
    "full stack developer",
    "problem solver",
    "mobile developer",
    "React.js developer"
  ];
  const job = tweened(iam[index], {
    duration: 2000,
    easing,
    interpolate: (a, b) => t => {
      if (t < 0.5) {
        return a.slice(0, Math.floor(a.length * (1 - 2 * t)));
      }
      return b.slice(0, Math.floor(b.length * (2 * t - 1)));
    }
  });

  onMount(() => {
    setInterval(() => {
      index = (index + 1) % iam.length;
      job.set(iam[index]);
    }, 6000);
  });
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
  }

  h3 {
    margin-top: 0;
  }

  .space-before {
    flex: 0 0 0;
  }

  .space-after {
    flex: 2 0 10vh;
  }

  a {
    color: white;
    font-weight: bold;
  }

  .job {
    font-weight: bold;
    animation: blink-caret 1s infinite;
    border: 0 solid white;
    border-right-width: 3px;
    padding-right: 0.25em;
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: white;
    }
  }
</style>

<div class="container">
  <div class="space-before" />
  <AutoHide>
    <h3>Who am I?</h3>
  </AutoHide>
  <AutoHide>
    <p>
      I'm a
      <span class="job">{$job}</span>
      based in Prague
      <br />
      and with
      <a href="http://gonzor.cz">Andrew</a>
      we co-founded
      <a href="http://company101.cz">Company 101</a>
    </p>
  </AutoHide>
  <AutoHide>
    <p>
      Here is my
      <a
        href="https://docs.google.com/document/d/1HuG7H6wAQmUUJnyS3JtPlIeXOQvA1eHDQv5SHx6z3vk/export?format=pdf">
        CV
      </a>
    </p>
  </AutoHide>
  <div class="space-after" />
</div>
