<script>
  import View from "./View.svelte";
  import Intro from "./Intro.svelte";
  import Contact from "./Contact.svelte";
  import About from "./About.svelte";
  import Projects from "./Projects.svelte";

  import { colorStore } from "./stores.js";

  $: {
    window.document.body.style.backgroundColor = $colorStore;
  }
</script>

<style>
  :root {
    --background-color: #35abff;
    --foreground-color: #ffffff;
  }

  .credits {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #eeeeeeaa;
  }
  .credits a {
    color: #bbbbff;
  }
</style>

<View color="#32abff">
  <Intro />
</View>
<View color="#2156aa">
  <About />
</View>
<View color="#32abff">
  <Projects />
</View>
<View color="#00b63b">
  <Contact />
</View>
<span class="credits">
  &copy; made by
  <a href="https://kaifer.cz">me</a>
  in {new Date().getFullYear()}
</span>
